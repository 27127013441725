import React from 'react';
import { TransactionsProps } from '../interfaces/transaction.interface';
import { MonitorCard } from './MonitorCard';

export function Monitor(props: { data: TransactionsProps[] }) {
  const { data } = props;
  let success = 0;
  let errors = 0;
  let all = 0;
  data.forEach((tran) => {
    tran.transactions.forEach((t) => {
      if (t.responseCode > 400) {
        errors++;
      } else {
        success++;
      }
      all++;
    });
  });
  const successPercentage = success > 0 ? +((success * 100) / all).toFixed(2) : 0;
  const errorPercentage = errors > 0 ? +((errors * 100) / all).toFixed(2) : 0;
  return (
    <div className='container overflow-hidden text-center'>
      <div className='row'>
        <div className='col'>
          <MonitorCard title='EXITOSAS' value={success} percent={successPercentage} color='text-success'></MonitorCard>
        </div>
        <div className='col'>
          <MonitorCard title='RECHAZADAS' value={errors} percent={errorPercentage} color='text-danger'></MonitorCard>
        </div>
      </div>
    </div>
  );
}
