import React, { useEffect, useState } from 'react';
import { endOfDay, format, startOfDay } from 'date-fns';
import './App.css';
import { Datepicker } from './components/Datepicker';
import { Monitor } from './components/Monitor';
import { Transactions } from './components/Transactions';

function MonitorVew() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const now = new Date();
  const [startDate, setStartDate] = useState(format(startOfDay(now), "yyyy-MM-dd'T'HH:mm"));
  const [endDate, setEndDate] = useState(format(endOfDay(now), "yyyy-MM-dd'T'HH:mm"));
  useEffect(() => {
    fetch(url(startDate, endDate))
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  function setDate() {
    setLoading(true);
    fetch(url(startDate, endDate))
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <div className='MonitorView pt-3 container-fluid'>
      {loading ? (
        <div className='d-flex justify-content-center mt-5'>
          <div className='spinner-border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <Monitor data={data}></Monitor>
          <Datepicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} setDate={setDate} />
          <Transactions data={data}></Transactions>
          <p>
            <small>v{process.env.REACT_APP_APP_VERSION || '0.0.0'}</small>
          </p>
        </>
      )}
    </div>
  );
}
function url(startDate: string, endDate: string) {
  return `https://xbfulfillment.plugnplay.build/v1/d934285e-3943-11ed-a261-0242ac120002/logs?startDate=${startDate}&endDate=${endDate}`;
}
export default MonitorVew;
