import { format, parseISO } from 'date-fns';
import React from 'react';
import { TransactionsLine, TransactionsProps } from '../interfaces/transaction.interface';

function findReferenceID(transactions: TransactionsLine[]) {
  try {
    const str = JSON.stringify(transactions);
    let data = '';
    if (str.includes('ReferenceID')) {
      data = str.split('ReferenceID')[1];
    } else if (str.includes('Reference_ID')) {
      data = str.split('Reference_ID')[1];
    } else if (str.includes('TransactionID')) {
      data = str.split('TransactionID')[1];
    }
    return data.split('"')[2];
  } catch (e) {
    return '';
  }
}
function findErrors(transactions: TransactionsLine[]) {
  let errors = 0;
  transactions.forEach((t) => {
    if (t.responseCode >= 400) {
      errors++;
    }
  });
  return errors;
}
export function Transactions(props: { data: TransactionsProps[] }) {
  const { data } = props;
  return (
    <div className='table-responsive pt-4'>
      <table className='table accordion table-hover m-0'>
        <thead>
          <tr>
            <th scope={'col'}>Origen</th>
            <th scope={'col'}>ID</th>
            <th scope={'col'}>Errores</th>
            <th scope={'col'}>Creado</th>
            <th scope={'col'}></th>
          </tr>
        </thead>
        <tbody id='accordionExample'>
          {data.map((tran, index) => {
            const errors = findErrors(tran.transactions);
            return (
              <React.Fragment key={index}>
                <tr className={errors > 0 ? 'text-danger' : ''}>
                  <td>{tran.originalUrl}</td>
                  <td>{findReferenceID(tran.transactions)}</td>
                  <td>{errors}</td>
                  <td>{format(parseISO(tran.createdAt), 'dd/MM/yyyy HH:mm')}</td>
                  <td>
                    <a href={'#tran' + index} data-bs-toggle='collapse'>
                      Ver
                    </a>
                  </td>
                </tr>
                <tr id={'tran' + index} className='accordion-collapse collapse sow' data-bs-parent='#accordionExample'>
                  <td colSpan={5}>
                    <table className='table accordion m-0'>
                      <thead>
                        <tr>
                          <th scope={'col'}>Modo</th>
                          <th scope={'col'}>Metodo</th>
                          <th scope={'col'}>Codigo</th>
                          <th scope={'col'}>Peticion</th>
                          <th scope={'col'}>Respuesta</th>
                          <th scope={'col'}>Mensage</th>
                          <th scope={'col'}>Creado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tran.transactions.map((t, i) => (
                          <tr key={t._id} className={t.responseCode >= 400 ? 'text-danger' : ''}>
                            <td>{t.mode}</td>
                            <td>{t.method}</td>
                            <td>{t.responseCode}</td>
                            <td>{JSON.stringify(t.requestBody)}</td>
                            <td>{JSON.stringify(t.responseBody)}</td>
                            <td>{t.responseMessage}</td>
                            <td>
                              {t.createdAt ? format(parseISO(t.createdAt), 'dd/MM/yyyy HH:mm') : format(parseISO(tran.createdAt), 'dd/MM/yyyy HH:mm')}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
