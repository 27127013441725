import React from 'react';

interface MonitorCardProps {
  title: string;
  value: number;
  color: string;
  percent: number;
}
export function MonitorCard(props: MonitorCardProps) {
  const { title, value, color, percent } = props;
  return (
    <div className='card' style={{ width: '200px', height: '200px', margin: 'auto' }}>
      <div style={{ margin: 'auto' }}>
        <div className={color}>
          <strong>{title}</strong>
        </div>
        <h1>{value}</h1>
        <small>({percent}%)</small>
      </div>
    </div>
  );
}
