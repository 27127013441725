import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MonitorView from './MonitorView';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/c0c6a98a-5878-4883-9509-c8cd03d8aae2' element={<MonitorView />} />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
