import React, { useState } from 'react';

export function Datepicker(props: { startDate: any; endDate: any; setEndDate: any; setStartDate: any; setDate: any }) {
  const { startDate, endDate, setEndDate, setStartDate, setDate } = props;

  return (
    <div className='row justify-content-end pt-5'>
      <div className='col-lg-3 col-sm-5'>
        <label htmlFor='startDate'>Desde</label>
        <input
          id='startDate'
          className='form-control'
          type='datetime-local'
          value={startDate}
          onChange={(event) => setStartDate(event.target.value)}
        />
        <span id='startDateSelected'></span>
      </div>
      <div className='col-lg-3 col-sm-5'>
        <label htmlFor='endDate'>Hasta</label>
        <input id='endDate' className='form-control' type='datetime-local' value={endDate} onChange={(event) => setEndDate(event.target.value)} />
        <span id='endDateSelected'></span>
      </div>
      <div className='col-lg-1 col-sm-1 pt-4'>
        <button type='button' className='btn btn-primary' onClick={(event) => setDate()}>
          Buscar
        </button>
      </div>
    </div>
  );
}
